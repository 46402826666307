<template>
  <v-card class="mx-auto mt-5" elevation="4" max-width="344">
    <v-card-title class="justify-center">Create A New Password</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col>
            <v-text-field
              v-model="newPassword"
              label="New Password"
              :rules="req"
              type="password"
              solo
              required
              :disabled="loading"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="confirmPassword"
              label="Confirm Password"
              type="password"
              solo
              required
              :disabled="loading"
              :rules="req"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        style="width: 100%"
        @click="submit"
        :disabled="loading"
        :loading="loading"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.v-card__title {
  text-align: center;
  width: 100%;
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "Login",
  mixins: [hasuraService],
  methods: {
    submit: async function() {
      if (this.$refs.form.validate()) {
        if (this.newPassword === this.confirmPassword) {
          this.loading = true;
          let result = await this.passwordResetTwo(
            this.$route.params.token,
            this.newPassword
          );
          console.log("result", result);
          if (result.ok) {
            this.$store.commit(
              "set_snackbar",
              "Success, please login using your new password"
            );
            this.$router.push("/login");
          } else {
            this.$store.commit(
              "set_snackbar",
              "Invalid Password Reset Link, Please check your email for the correct link, or contact matt@annuitycheck.com"
            );
            this.loading = false;
          }
          this.loading = false;
        } else {
          this.$store.commit("set_snackbar", "Passwords do not match");
        }
      } else {
        this.$store.commit("set_snackbar", "Please fill out the form");
      }
    },
  },
  data: () => {
    return {
      newPassword: "",
      confirmPassword: "",
      loading: false,
      valid: true,
      req: [(v) => !!v || "Field is required"],
    };
  },
};
</script>
